import React from 'react'
import Email from './Email'
import Image from './Image'

import './Advisor.css'

const Advisor = ({ img, name, position, phone, email, description }) => (
  <div className="advisor">
    <div className="photo">
      <Image src={`advisors/${img}`} alt={name} />
    </div>
    <div className="content">
      <h2>{name}</h2>
      <h3>{position}</h3>
      <div className="phone">{phone}</div>
      <Email email={email} label="Send e-post" />
      <div className="description">{description}</div>
    </div>
  </div>
)

export default Advisor
